export const MintButton = ({ onMint, candyMachine, isMinting }) => {
  const getMintButtonContent = () => {
    if (candyMachine?.state.isSoldOut) {
      return 'SOLD OUT';
    }
    if (isMinting) {
      return 'Minting...';
    }
    return <span className="font-thinSugar"> BUY NOOT JUICE </span>;
  };

  if (candyMachine?.state.isSoldOut) {
    return (
      <button className="btn btn-disabled text-5xl text-white">{getMintButtonContent()}</button>
    );
  }
  if (isMinting) {
    return (
      <button className="btn btn-disabled loading text-xl text-white">
        {getMintButtonContent()}
      </button>
    );
  }
  return (
    <button className="btn btn-primary text-2xl" onClick={() => onMint()}>
      {getMintButtonContent()}
    </button>
  );
};
