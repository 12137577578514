import ConnectWalletButton from '../components/ConnectWalletButton';
import PirateGreeting from '../components/PirateGreeting';

export default function SmugglersPage() {
  return (
    <div className="flex flex-col w-full h-full min-h-screen bg-slate-50">
      <div className="flex py-2 px-4 w-full justify-between items-center">
        <p className="pt-3 font-sugar text-3xl md:text-5xl">Smuggler&apos;s Den</p>
        <ConnectWalletButton />
      </div>

      <PirateGreeting />
    </div>
  );
}
