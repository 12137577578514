import { useMemo } from 'react';
import Countdown from 'react-countdown';

export default function JuiceCountdown({ className, startDate }) {
  const date = useMemo(() => new Date(startDate * 1000), [startDate]);

  if (!startDate) {
    return null;
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    }
    return (
      <div className="flex space-around justify-center w-48 text-xl font-mono">
        {days > 0 && (
          <p>
            {days} {days > 1 ? 'Days' : 'Day'}&nbsp;
          </p>
        )}
        <p>
          {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
          {String(seconds).padStart(2, '0')}
        </p>
      </div>
    );
  };

  return (
    <div className={className}>
      <Countdown date={date} renderer={renderer} />
    </div>
  );
}
