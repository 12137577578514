import Image from 'next/image';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import * as anchor from '@project-serum/anchor';
import { useWallet, useConnection } from '@solana/wallet-adapter-react';

import {
  awaitTransactionSignatureConfirmation,
  createAccountsForMint,
  getCandyMachineState,
  getCollectionPDA,
  mintOneToken,
} from '../lib/candyMachine';

import { getAtaForMint } from '../lib/candyUtils';
import { DEFAULT_TIMEOUT } from '../lib/connection';
import { CMID, getNetworkUri } from '../lib/config';

import JuiceCountdown from './JuiceCountdown';
import { MintButton } from './MintButton';

const candyMachineAddress = new anchor.web3.PublicKey(CMID);

export default function PirateGreeting() {
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [candyMachine, setCandyMachine] = useState();
  const [isActive, setIsActive] = useState(false);
  const [startDate, setStartDate] = useState();
  const [itemsRemaining, setItemsRemaining] = useState();
  const [, setIsPresale] = useState(false);
  const [isValidBalance, setIsValidBalance] = useState(false);
  const [needTxnSplit, setNeedTxnSplit] = useState(true);
  const [setupTxn, setSetupTxn] = useState();

  const rpcUrl = getNetworkUri();
  const wallet = useWallet();
  const { connection } = useConnection();

  const refreshCandyMachineState = useCallback(async () => {
    if (!(wallet && wallet.publicKey)) {
      console.warn("No anchor wallet, can't refresh candy machine", wallet);
      return;
    }

    if (!CMID) {
      toast.error("Your CMID value doesn't look right!");
      return;
    }
    try {
      const cndy = await getCandyMachineState(wallet, candyMachineAddress, connection);
      setStartDate(cndy?.state.goLiveDate?.toNumber());
      // let active = cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000;
      let active =
        cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000 ||
        wallet.publicKey.toBase58() === 'ELixrhbh7rtfdKGnQNfkJ5ktmQtnGzyrHi7GchpGk6ED';
      let presale = false;

      // duplication of state to make sure we have the right values!
      let isWLUser = false;
      let userPrice = cndy.state.price;

      userPrice = isWLUser ? userPrice : cndy.state.price;

      if (cndy?.state.tokenMint) {
        // retrieves the SPL token
        const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
        const token = (await getAtaForMint(mint, wallet.publicKey))[0];
        try {
          const balance = await connection.getTokenAccountBalance(token);
          const valid = new anchor.BN(balance.value.amount).gte(userPrice);

          // only allow user to mint if token balance >  the user if the balance > 0
          setIsValidBalance(valid);
          active = active && valid;
        } catch (e) {
          setIsValidBalance(false);
          active = false;
          // no whitelist user, no mint
          console.log('There was a problem fetching SPL token balance');
          console.log(e);
        }
      } else {
        const balance = new anchor.BN(await connection.getBalance(wallet.publicKey));
        const valid = balance.gte(userPrice);
        setIsValidBalance(valid);
        active = active && valid;
      }

      setItemsRemaining(cndy.state.itemsRemaining);
      if (cndy.state.isSoldOut) {
        active = false;
      }

      const [collectionPDA] = await getCollectionPDA(candyMachineAddress);
      const collectionPDAAccount = await connection.getAccountInfo(collectionPDA);

      setIsActive((cndy.state.isActive = active));
      setIsPresale((cndy.state.isPresale = presale));
      setCandyMachine(cndy);

      const txnEstimate =
        892 +
        (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
        (cndy.state.tokenMint ? 66 : 0) +
        (cndy.state.whitelistMintSettings ? 34 : 0) +
        (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
        (cndy.state.gatekeeper ? 33 : 0) +
        (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

      setNeedTxnSplit(txnEstimate > 1230);
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === `Account does not exist ${CMID}`) {
          toast.error(
            `Couldn't fetch candy machine state from candy machine with address: ${CMID}, using rpc: ${rpcUrl}!`
          );
        } else if (e.message.startsWith('failed to get info about account')) {
          toast.error(`Couldn't fetch candy machine state with rpc: ${rpcUrl}!`);
        }
      } else {
        toast.error(`${e}`);
      }
      console.log(e);
    }
  }, [connection, wallet, rpcUrl]);

  useEffect(() => {
    if (!(wallet && wallet.publicKey && connection)) {
      return;
    }
    refreshCandyMachineState();
  }, [wallet, wallet.publicKey, connection, refreshCandyMachineState]);

  // Refresh candy machine state every 20 seconds
  useEffect(() => {
    if (!wallet.publicKey) {
      return;
    }
    const intervalId = setInterval(refreshCandyMachineState, 20000);
    return () => clearInterval(intervalId);
  }, [wallet.publicKey, refreshCandyMachineState]);

  // Refresh the candy machine when the timer reaches 0
  useEffect(() => {
    const remainingMs = new Date(startDate * 1000).getTime() - new Date().getTime();
    if (remainingMs < 1) {
      return;
    }
    const timeoutId = setTimeout(() => {
      refreshCandyMachineState();
    }, remainingMs);
    return () => clearTimeout(timeoutId);
  }, [refreshCandyMachineState, startDate]);

  const onMint = async (beforeTransactions = [], afterTransactions = []) => {
    try {
      setIsUserMinting(true);
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        let setupMint;
        if (needTxnSplit && setupTxn === undefined) {
          toast.info('Please sign account setup transaction');
          setupMint = await createAccountsForMint(candyMachine, wallet.publicKey);
          let status = { err: true };
          if (setupMint.transaction) {
            status = await awaitTransactionSignatureConfirmation(
              setupMint.transaction,
              DEFAULT_TIMEOUT,
              connection,
              true
            );
          }
          if (status && !status.err) {
            setSetupTxn(setupMint);
            // toast.info('Transaction prepared. Please sign transaction.');
          } else {
            toast.error('Mint failed! Please try again!');
            setIsUserMinting(false);
            return;
          }
        } else {
          // toast.info('Please sign minting transaction');
        }

        let mintResult = await mintOneToken(
          candyMachine,
          wallet.publicKey,
          beforeTransactions,
          afterTransactions,
          setupMint ?? setupTxn
        );

        let status = { err: true };
        let metadataStatus = null;
        if (mintResult) {
          status = await awaitTransactionSignatureConfirmation(
            mintResult.mintTxId,
            DEFAULT_TIMEOUT,
            connection,
            true
          );

          metadataStatus = await candyMachine.program.provider.connection.getAccountInfo(
            mintResult.metadataKey,
            'processed'
          );
          console.log('Metadata status: ', !!metadataStatus);
        }

        if (status && !status.err && metadataStatus) {
          // manual update since the refresh might not detect
          // the change immediately
          let remaining = itemsRemaining - 1;
          setItemsRemaining(remaining);
          setIsActive((candyMachine.state.isActive = remaining > 0));
          candyMachine.state.isSoldOut = remaining === 0;
          setSetupTxn(undefined);
          toast('Congratulations! Juice acquired!');
          refreshCandyMachineState('processed');
        } else if (status && !status.err) {
          toast.error(
            'Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.'
          );
          refreshCandyMachineState();
        } else {
          toast.error('Mint failed! Please try again!');
          refreshCandyMachineState();
        }
      }
    } catch (error) {
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (!error.message) {
          message = 'Transaction timeout! Please try again.';
        } else if (error.message.indexOf('0x137')) {
          console.warn(error);
          message = 'SOLD OUT!';
        } else if (error.message.indexOf('0x135')) {
          message = 'Insufficient funds to mint. Please fund your wallet.';
        }
      } else {
        if (error.code === 311) {
          console.warn(error);
          message = 'SOLD OUT!';
          window.location.reload();
        } else if (error.code === 312) {
          message = "Minting period hasn't started yet.";
        }
      }

      toast.error(message);
      // updates the candy machine state to reflect the latest
      // information on chain
      refreshCandyMachineState();
    } finally {
      setIsUserMinting(false);
    }
  };

  const text = useMemo(() => {
    if (!(wallet && wallet.publicKey)) {
      return (
        <div className="mt-2">
          You here for the <span className="font-thinSugar text-2xl">Juice?</span>
          <span className="font-bold"> Connect yer wallet!</span>
        </div>
      );
    }
    if (!isActive && startDate > new Date().getTime() / 1000) {
      return "Heard about the Noot Juice, have ye? Me crew be up in the Eyrie smugglin' out a batch. Should be here soon!";
    }
    if (!isValidBalance) {
      return (
        <span>
          Interested in some Noot Juice, are ye? Come back when ye&apos;ve got at least{' '}
          <span className="font-thinSugar text-2xl">33 $PESKY</span>. Smuggling&apos;s a risky
          business!
        </span>
      );
    } else if (!isActive || itemsRemaining < 1) {
      return "Arghhh ye just missed it! We're fresh out o' Juice. Don't fret; I've got a crack team of pengus smuggling out another batch. Check back soon!";
    }
    return (
      <div>
        <p>
          Come fer a taste o&apos; the{' '}
          <span className="font-thinSugar text-2xl tracking-tighter">Juice,</span> have ye? These
          fishes were hard to come by! A bottle&apos;ll cost ye
        </p>
        <div className="mt-2 flex w-full items-center justify-center">
          <div className="font-thinSugar text-2xl">33 $PESKY.</div>
        </div>
      </div>
    );
  }, [isActive, startDate, isValidBalance, itemsRemaining, wallet]);

  return (
    <div className="mb-4 md:mb-0 px-4 flex items-center justify-center flex-wrap">
      {candyMachine?.state.isSoldOut ? (
        <Image src="/noot-juice-crates.png" width={400} height={400} alt="Empty Crates" />
      ) : (
        <Image src="/pirate-noot.png" width={400} height={400} alt="Pesky Smuggler" />
      )}

      <div className="card w-80 md:w-128 bg-neutral text-neutral-content font-thinSugar">
        <div className="card-body items-center text-center p-4 py-6">
          <h2 className="card-title text-4xl">
            {candyMachine && itemsRemaining < 1 ? 'Sold Out!' : 'Ahoy there!'}
          </h2>
          <div className="max-w-96 font-sans">{text}</div>
          <div className="h-1" />

          {!isActive && startDate > new Date().getTime() / 1000 && (
            <JuiceCountdown startDate={startDate} />
          )}

          {wallet && isActive && (
            <div className="flex flex-col w-full items-center justify-center">
              <MintButton
                candyMachine={candyMachine}
                isMinting={isUserMinting}
                setIsMinting={(val) => setIsUserMinting(val)}
                onMint={onMint}
                isActive={isActive}
              />

              {itemsRemaining && (
                <div className="font-mono mt-4 text-sm">Bottles remaining: {itemsRemaining}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
