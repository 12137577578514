import { useMemo } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDisconnectButton, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { getPubkeyShorthand } from '../lib/utils';

import '@solana/wallet-adapter-react-ui/styles.css';

export default function ConnectWalletButton() {
  const { publicKey } = useWallet();

  const pubkeyShorthand = useMemo(() => {
    const [start, end] = getPubkeyShorthand(publicKey);
    return (
      <span className="font-mono font-light">
        {start}&hellip;{end}
      </span>
    );
  }, [publicKey]);

  return (
    <div className="bg-blue-800" style={{ minWidth: '150px' }}>
      {publicKey ? (
        <WalletDisconnectButton>
          <div className="flex flex-col leading-snug">
            <p>Disconnect</p>
            <p className="text-sm font-mono">{pubkeyShorthand}</p>
          </div>
        </WalletDisconnectButton>
      ) : (
        <WalletMultiButton />
      )}
    </div>
  );
}
