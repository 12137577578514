import { isEmpty, isString } from 'lodash-es';
import { PublicKey } from '@solana/web3.js';

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Borsh-js doesn't support i64 or f64, so read as 8-byte arrays
// and convert them using DataView helper
export function bytesToFloat64(bytes) {
  let number = new DataView(bytes.buffer).getFloat64(0, true);
  return Number(number);
}

export function bytesToInt64(bytes) {
  let number = new DataView(bytes.buffer).getBigInt64(0, true);
  return Number(number);
}

export function bytesToHex(bytes) {
  return Array.from(bytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
}

// Convert a hex string to a byte array
export function hexToBytes(hex) {
  const bytes = [];
  for (let c = 0; c < hex.length; c += 2) {
    bytes.push(parseInt(hex.substr(c, 2), 16));
  }
  return Uint8Array.from(bytes);
}

/**
 * Converts period length (seconds) to a durationInfo object
 */
export function getDurationInfo(period) {
  let unit = 'Minute(s)';
  let qty = (period / 60).toFixed(2).replace(/\.00/, '');
  if (period > 3600) {
    unit = 'Hour(s)';
    qty = (period / 3600).toFixed(2).replace(/\.00/, '');
  }
  if (period > 3600 * 24) {
    unit = 'Day(s)';
    qty = (period / 3600 / 24).toFixed(2).replace(/\.00/, '');
  }
  return { unit, qty };
}

export function isTimestampInvalid(timestamp) {
  const validThreshold = new Date(2022, 1, 1).getTime() / 1000;
  return timestamp < validThreshold;
}

export function getCurrentPay(stakeData) {
  if (!stakeData || isEmpty(stakeData)) {
    return 0;
  }
  const { timestamp, maxPayout, payRate, payPeriod } = stakeData;
  if (isTimestampInvalid(timestamp)) {
    return 0;
  }
  const now = new Date() / 1000;
  const elapsed = now - timestamp;
  const nextPP = Math.floor(Math.abs((elapsed % payPeriod) - payPeriod));
  if (isNaN(nextPP) || nextPP < 0) {
    return 0;
  }
  const elapsedPayPeriods = Math.floor(elapsed / payPeriod);
  const pay = payRate * elapsedPayPeriods;
  return Math.min(pay, maxPayout);
}

const NFT_NUMBER_REGEX = /^.+\#(\d+)$/;
export function getPenguinNumber(name) {
  try {
    const [, number] = name.match(NFT_NUMBER_REGEX);
    return parseInt(number, 10);
  } catch (err) {
    console.warn('Failed to parse number from', name);
    return null;
  }
}

export function sortByNFTNumber(a, b) {
  const { name: aName } = a;
  const { name: bName } = b;
  try {
    const aNumber = parseInt(aName.match(NFT_NUMBER_REGEX)[1], 10);
    const bNumber = parseInt(bName.match(NFT_NUMBER_REGEX)[1], 10);
    return aNumber - bNumber;
  } catch (err) {
    console.warn('Failed to parse NFT number for', aName, bName);
    if (aName < bName) {
      return -1;
    }
    return 1;
  }
}

export function getPubkeyShorthand(rawPublicKey) {
  let publicKey = rawPublicKey;
  if (isString(rawPublicKey)) {
    publicKey = new PublicKey(rawPublicKey);
  }
  if (!(publicKey && publicKey.toBase58)) {
    return '';
  }
  const s = publicKey.toBase58();
  return [s.slice(0, 4), s.slice(-4)];
}

export function getUnixTime() {
  return new Date().valueOf() / 1000;
}

export function getPenguinType(uri) {
  const segments = uri.split('-');
  const [penguinType] = segments[segments.length - 1].split('.');
  return penguinType;
}
